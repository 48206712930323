// Страница с бисквитными тортами

import rafaello from "./img/item/Бисквитные/Рафаэлло.jpg";
import fistashka from "./img/item/Бисквитные/Фисташка-Малина.jpg";
import profitrolli from "./img/item/Бисквитные/Шоколад-Профитроли.jpg";
import mak from "./img/item/Бисквитные/Мак-Смородина.jpg";
import laim from "./img/item/Бисквитные/Лайм-Мята.jpg";
import pecan from "./img/item/Бисквитные/Морковный с орехом пекан.jpg";
import yabloko from "./img/item/Бисквитные/Карамельное яблоко.jpg";
import vishnya from "./img/item/Бисквитные/Шпинат-Вишня.jpg";
import oreo from "./img/item/Бисквитные/Орео.jpg";
import mokko from "./img/item/Бисквитные/Ореховое мокко.jpg";
import barhat from "./img/item/Бисквитные/Красный бархат.jpg";
import oreh from "./img/item/Бисквитные/Морковный с грец орехом.jpg";
import beilis from "./img/item/Бисквитные/Вишня-Бейлис.jpg";
import medovik from "./img/item/Бисквитные/Медовик.jpg";
import medovik2 from "./img/item/Бисквитные/Медовик сметан крем.jpg";
import grusha from "./img/item/Бисквитные/Карамельная груша.jpg";
import plombir from "./img/item/Бисквитные/Ягодный пломбир.jpg";
import yogurt_sufle from "./img/item/Бисквитные/Йогутовое суфле.jpg";

export const biscuitList = {
  productList: [
    {
      id: "bt1",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Рафаэлло"',
      img: rafaello,
      desc: [
        "Нежнейший ванильный бисквит",
        "Лёгкий творожно-заварной крем с кокосовой стружкой",
        "Хрустящая вафельная прослойка на основе белого шоколада",
      ],
      price: 2100,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.2, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "bt2",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Фисташка-Малина"',
      img: fistashka,
      desc: [
        "Нежнейший фисташковый бисквит",
        "Малиновый конфитюр",
        "Малиновый мусс с кусочками малины и фисташки",
        "Фисташковая меренга и нежный творожно-фисташковый крем",
      ],
      price: 2200,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt3",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Шоколад-Профитроли"',
      img: profitrolli,
      desc: [
        "Шоколадный бисквит",
        "Нежный ванильный крем чиз",
        "Хрустящая крошка в шоколаде",
        "Ароматный шоколадный мусс",
        "Профитроли с ванильным кремом",
      ],
      price: 2200,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt4",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Мак-Смородина"',
      img: mak,
      desc: [
        "Маковый и лимонный бисквит",
        "Лимонный курд",
        "Чёрносмородиновый конфитюр",
        "Ванильный крем чиз",
        "Чёрносмородиновый мусс с кусочками чёрной смородины",
      ],
      price: 1900,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt5",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Лайм-Мята"',
      img: laim,
      desc: [
        "Освежающий мятно-лаймовый бисквит",
        "Нежный творожный крем",
        "Ванильный мусс с клубничными и апельсиновыми шариками",
        "Клубничный конфитюр",
        "Апельсиновый курд",
      ],
      price: 2500,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt6",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Морковный с орехом пекан"',
      img: pecan,
      desc: [
        "Ароматный морковный бисквит",
        "Шоколадно-сливочный крем с добавлением чернослива и орехов пекан",
      ],
      price: 2000,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt7",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Карамельное яблоко"',
      img: yabloko,
      desc: [
        "Ароматный карамельный бисквит",
        "Яблочный мусс с лёгкой кислинкой",
        "Варёная домашняя карамель",
        "Карамелизированные яблоки с корицей",
        "Карамельный крем без масла",
      ],
      price: 1900,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt8",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Шпинат-Вишня"',
      img: vishnya,
      desc: [
        "Сочный шпинатный бисквит (вкус шпината не чувствуется)",
        "Вишнёвый конфитюр",
        "Вишнёвый мусс с кусочками вишни",
        "Нежный творожно-заварной крем",
      ],
      price: 2000,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt9",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Орео"',
      img: oreo,
      desc: [
        "Шоколадный бисквит",
        "Творожно-ванильный крем",
        "Ароматный шоколадный крем на основе белого шоколада с кусочками печенья “Орео”",
      ],
      price: 1900,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt10",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Ореховое мокко"',
      img: mokko,
      desc: [
        "Ароматный ореховый бисквит",
        "Шоколадный крем",
        "Кофейный крем",
        "Пропитка на выбор (бейлис/кофе)",
      ],
      price: 2000,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt11",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Красный бархат"',
      img: barhat,
      desc: [
        "Ароматный шоколадный бисквит тёмно-красного цвета",
        "Творожно-сливочный крем чиз",
      ],
      price: 1900,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt12",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Морковный с грецким орехом"',
      img: oreh,
      desc: [
        "Ароматный морковный бисквит с грец орехом",
        "Творожно-сырный крем",
      ],
      price: 1700,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt13",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Вишня-Бейлис"',
      img: beilis,
      desc: [
        "Шоколадный бисквит, пропитанный ликером (можно без него)",
        "Вишнёвый конфитюр",
        "Шоколадно-сырный крем",
      ],
      price: 2000,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt14",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Медовик"',
      img: medovik,
      desc: ["Нежные ароматные коржи", "Творожно-заварной крем"],
      price: 1900,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt15",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Медовик в сметанном креме"',
      img: medovik2,
      desc: ["Тонкие ароматные медовые коржи", "Нежный сметанный крем"],
      price: 1700,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt16",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Карамельная груша"',
      img: grusha,
      desc: [
        "Нежный ореховый бисквит",
        "Домашняя варёная карамель с грецкими орехами",
        "Карамелизированные груши с корицей",
        "Нежный сливочно-сырный крем",
        "Сливочный мусс",
      ],
      price: 2000,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt17",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Ягодный пломбир"',
      img: plombir,
      desc: [
        "Нежный ванильный бисквит",
        "Ванильный крем чиз",
        "Ягодный конфитюр на выбор",
      ],
      price: 1700,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
    {
      id: "bt18",
      groupProduct: "Бисквитный торт",
      nameProduct: '"Йогуртовое суфле"',
      img: yogurt_sufle,
      desc: [
        "Нежный ванильный бисквит",
        "Ароматное йогуртовое суфле",
      ],
      price: 1700,
      unit: "кг.",
      minCount: 2,
      stepCount: 0.2,
      discount:0 //Скидка в %
    },
  ],
  decorList: [
    { dataDecor: "Базовое оформление", priceDecor: 0 },
    { dataDecor: "Леденцами из изомальта", priceDecor: 200 },
    { dataDecor: "Шоколадными шариками", priceDecor: 200 },
    { dataDecor: "Цветами из вафельной бумаги", priceDecor: 500 },
    { dataDecor: "Сложный декор", priceDecor: 1000 },
    { dataDecor: "Роспись торта", priceDecor: 700 },
    { dataDecor: "Рисунком в акварельной технике", priceDecor: 500 },
    { dataDecor: "Свежими ягодами", priceDecor: 200 },
    { dataDecor: "Фигурками ручной работы", priceDecor: 300 },
    { dataDecor: "Фигурками из пряника", priceDecor: 600 },
    { dataDecor: "Картинками на сахарной бумаге", priceDecor: 350 },
  ],
};
